import React, { useEffect, useState } from 'react';
import { Modal, Typography, Row, Col, Input, Button, Divider, Select, message } from 'antd';
import './index.css';
import { formatPrice } from '@contants/formatPrice';
import { formatVNDCurrency } from '@utils/utils';
import { NumericFormat } from 'react-number-format';
import { apiBidSnipping, apiGetSnippingType } from '@services/ManageUser/UserServices';
const { Text } = Typography;

const DialogBidLastMinute = ({
  isModalVisible,
  setIsModalVisible,
  remainingTime,
  data,
  exchangeRateJPY,
  customerId,
  roleSale
}) => {
  const calculatorNextPrice = () => {
    const start_price = parseInt(data?.detail?.start_price, 10) || 0;
    const current_price = parseInt(data?.price?.value, 10) || 0;
    if (start_price == current_price) return current_price;
    if (current_price < 1000) return current_price + 10;
    else if (1000 <= current_price && current_price < 5000) return current_price + 100;
    else if (5000 <= current_price && current_price < 10000) return current_price + 250;
    else if (10000 <= current_price && current_price < 50000) return current_price + 500;
    else return current_price + 1000;
  };
  const [bidPrice, setBidPrice] = useState(calculatorNextPrice());
  const [bidTimes, setBidTimes] = useState('MINUTE_1');

  const [snippingTypes, setSnippingTypes] = useState(false);
  const [loadingBid, setLoadingBid] = useState(false);

  const fetchSnippingType = async () => {
    try {
      const res = await apiGetSnippingType();
      const convertData = res?.data?.data?.map((item) => ({
        label: item.description,
        value: item?.name
      }));
      setSnippingTypes(convertData);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOk = async () => {
    try {
      setLoadingBid(true);
      if (bidPrice < calculatorNextPrice()) {
        message.error(`Giá săn phải lớn hơn ${calculatorNextPrice()}`);
        return;
      }
      const payload = {
        product_id: data?.id,
        price: +bidPrice,
        customer_id: roleSale ? +customerId : null,
        sniping_type: bidTimes
      };
      const res = await apiBidSnipping(payload);
      message.success('Đăng ký săn phút chót thành công !');
      setIsModalVisible(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingBid(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const inputWrapperStyle = {
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  };

  const suffixStyle = {
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
    color: '#626D7C',
    fontSize: '14px'
  };

  const inputStyle = {
    height: '36px',
    border: '1px solid #d9d9d9',
    borderRadius: '6px',
    padding: '4px 11px',
    width: '100%',
    fontSize: '14px',
    outline: 'none',
    boxShadow: 'none',
    transition: 'border 0.3s, box-shadow 0.3s',
    paddingRight: '50px' // Extra space for the suffix
  };

  const CountdownTimer = ({ timeLeft }) => {
    const { days, hours, minutes, seconds } = timeLeft;
    return (
      <>
        {seconds !== undefined ? (
          <div
            style={{
              padding: '4px 8px 4px 8px',
              backgroundColor: '#f4f5f7',
              borderRadius: '4px',
              display: 'inline-flex',
              gap: '4px',
              alignItems: 'center',
              height: '28px',
              width: '224px',
              marginBottom: '16px'
            }}>
            <Text
              style={{
                color: '#192434',
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '1'
              }}>
              {days}
            </Text>
            <Text
              style={{
                color: '#626d7c',
                fontSize: '12px',
                lineHeight: '1',
                paddingBottom: '2px'
              }}>
              ngày
            </Text>

            <Text
              style={{
                color: '#192434',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '1'
              }}>
              :
            </Text>

            <Text
              style={{
                color: '#192434',
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '1'
              }}>
              {hours}
            </Text>
            <Text
              style={{
                color: '#626d7c',
                fontSize: '12px',
                lineHeight: '1',
                paddingBottom: '2px'
              }}>
              giờ
            </Text>

            <Text
              style={{
                color: '#192434',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '1'
              }}>
              :
            </Text>

            <Text
              style={{
                color: '#192434',
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '1'
              }}>
              {minutes}
            </Text>
            <Text
              style={{
                color: '#626d7c',
                fontSize: '12px',
                lineHeight: '1',
                paddingBottom: '2px'
              }}>
              phút
            </Text>

            <Text
              style={{
                color: '#192434',
                fontSize: '14px',
                fontWeight: '400',

                lineHeight: '1'
              }}>
              :
            </Text>

            <Text
              style={{
                color: '#192434',
                fontSize: '14px',
                fontWeight: '500',

                lineHeight: '1'
              }}>
              {seconds}
            </Text>
            <Text
              style={{
                color: '#626d7c',
                fontSize: '12px',

                lineHeight: '1',
                paddingBottom: '2px'
              }}>
              giây
            </Text>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  useEffect(() => {
    fetchSnippingType();
  }, []);

  return (
    <>
      <Modal
        title="Săn phút chót"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Săn phút chót"
        cancelText="Hủy bỏ"
        okButtonProps={{
          style: {
            backgroundColor: '#00904A',
            borderColor: '#00904A',
            color: '#fff',
            height: '36px'
          },
          loading: loadingBid
        }}
        cancelButtonProps={{
          style: {
            backgroundColor: '#E8EBEF',
            borderColor: '#E8EBEF',
            color: '#192434',
            height: '36px'
          }
        }}
        centered>
        <Typography.Title className="product-title" style={{ fontSize: '16px' }}>
          {data?.title?.text}
        </Typography.Title>
        <Typography.Title style={{ color: '#626D7C', fontWeight: '500', fontSize: '14px' }}>
          Thời gian đấu giá
        </Typography.Title>
        <CountdownTimer timeLeft={remainingTime} />
        {/* Current Price */}
        <div className="price-section">
          <Text style={{ color: '#626D7C', fontSize: '14px', fontWeight: '500' }}>
            Giá hiện tại:
          </Text>
          <Row align="middle" justify="space-between">
            <Col>
              <Text style={{ fontSize: '18px', fontWeight: '600' }}>
                {formatPrice(data?.price.value)} ¥{' '}
              </Text>
              <Text type="secondary">
                (≈{formatVNDCurrency(data?.price.value * exchangeRateJPY)} đ)
              </Text>
            </Col>
          </Row>
        </div>

        {/* Shipping Fee */}
        <div className="price-section">
          <Text style={{ color: '#626D7C', fontSize: '14px', fontWeight: '500' }}>
            Phí vận chuyển nội địa:
          </Text>
          <Row align="middle" justify="space-between">
            <Col>
              <Text style={{ fontSize: '18px', fontWeight: '600' }}>
                {formatPrice(data?.price?.transportLocal)} ¥{' '}
              </Text>
              <Text type="secondary">
                (≈{formatVNDCurrency(data?.price.transportLocal * exchangeRateJPY)} đ)
              </Text>
            </Col>
          </Row>
        </div>

        {/* Last-Minute Price */}
        <div className="price-section">
          <Text type="secondary">Giá săn phút chót (¥):</Text>
          <div className="last-minute-input">
            <div style={inputWrapperStyle}>
              <NumericFormat
                // {...field}
                value={bidPrice}
                thousandSeparator=","
                decimalScale={2}
                className="ant-input"
                style={inputStyle}
                onValueChange={(values) => {
                  setBidPrice(values?.floatValue);
                }}
              />
              <div style={suffixStyle}>≈{formatVNDCurrency(bidPrice * exchangeRateJPY)} đ</div>
            </div>
          </div>
          <Text type="secondary" className="price-note">
            Giá tối thiểu: {formatPrice(calculatorNextPrice())} ¥
          </Text>
        </div>

        {/* Bid Timing */}
        <div className="price-section">
          <Text type="secondary">Thời gian đặt giá săn:</Text>
          <Select
            placeholder={'Lựa chọn'}
            value={bidTimes}
            style={{ width: '100%', height: '36px' }}
            onChange={(option) => {
              setBidTimes(option);
            }}
            options={snippingTypes || []}
          />
        </div>
      </Modal>
    </>
  );
};

export default DialogBidLastMinute;
