import React, { useEffect, useState } from 'react';
import { Button, Card, Space, Typography } from 'antd';
import { HeartOutlined, ClockCircleOutlined, HeartFilled } from '@ant-design/icons';
import { formatCurrency, getTimeDifferenceV2 } from '@utils/utils';
import { useNavigate } from 'react-router-dom';
import { loadFromLocalStorage } from '@databases/localStorage';
import { constantExchangeRate } from '@contants/index';
import { calculateTimeLeft } from '@contants/formatDayTime';

const { Title, Text } = Typography;

export const ProductItem = ({
  imgUrl,
  title,
  targetUrl,
  time,
  priceYen,
  priceDong,
  endDate,
  auctionId
}) => {
  const navigate = useNavigate();
  const [favorite, setFavorite] = useState(false);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft, endDate]);

  const { days, hours, minutes, seconds } = timeLeft;
  const handleClick = () => {
    navigate(`/account/auction/${auctionId}`);
  };
  const exchangeRateJPY = JSON.parse(
    loadFromLocalStorage(constantExchangeRate.EXCHANGE_RATE)
  )?.bank_sell;
  return (
    <Card
      cover={
        <img
          alt={title}
          src={imgUrl}
          style={{
            height: '164px',
            width: '100%',
            objectFit: 'cover',
            cursor: 'pointer'
          }}
          onClick={handleClick}
        />
      }
      style={{
        height: '312px'
      }}
      bodyStyle={{
        padding: '12px'
      }}>
      <Space direction="vertical" size="8px" style={{ width: '100%' }}>
        {seconds !== undefined ? (
          <Space size="small" align="start" style={{ marginLeft: '0px', marginTop: '0px' }}>
            <ClockCircleOutlined style={{ fontSize: '16px', color: '#626D7C' }} />

            <Text
              style={{
                fontSize: '14px'
              }}>{`${days}d - ${hours}h : ${minutes}' : ${seconds}s`}</Text>
          </Space>
        ) : (
          <Space size="small" align="start" style={{ marginLeft: '0px', marginTop: '0px' }}>
            <ClockCircleOutlined style={{ fontSize: '16px', color: '#626D7C' }} />

            <Text
              style={{
                fontSize: '14px',
                color: '#F82859',
                fontWeight: '500'
              }}>
              Đấu giá kết thúc
            </Text>
          </Space>
        )}
        <Title
          style={{
            fontSize: '14px',
            fontWeight: '500',
            cursor: 'pointer',
            marginBottom: '0px'
          }}
          onClick={handleClick}>
          {title}
        </Title>
        <Space size={'small'} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Title
              style={{
                fontSize: '16px',
                fontWeight: '600',
                color: '#F82859',
                marginBottom: '4px'
              }}>
              {`¥ ${formatCurrency(priceYen)}`}
            </Title>
            <Text
              style={{
                fontSize: '14px',
                fontWeight: '400',
                color: '#626D7C',
                marginBottom: '0px'
              }}>
              {`≈ ${formatCurrency(priceDong * exchangeRateJPY, 2)} đ`}
            </Text>
          </div>
          <Button
            type="text"
            icon={
              favorite ? (
                <HeartFilled style={{ color: '#626D7C' }} />
              ) : (
                <HeartOutlined style={{ color: '#626D7C' }} />
              )
            }
            key="favorite"
            style={{ borderRadius: '36px' }}
            onClick={() => setFavorite(!favorite)}
          />
        </Space>
      </Space>
    </Card>
  );
};
