import rootApi from '@utils/baseAPI/WrapperApi';

export const apiGetUserInfo = (data) => {
  return rootApi.get('/users/me', { params: data });
};

export const apiGetAllUser = (data) => {
  return rootApi.get('/admin/users/operator', { params: data });
};

export const apiDisableUser = () => {
  return rootApi.delete('/users/disable');
};
export const apiUpdateAvatar = (data) => {
  return rootApi.post('/users/update/avatar', data);
};

export const apiLoadAvatar = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return rootApi.post('/users/upload/avatar', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const apiGetSnippingType = (data) => {
  return rootApi.get('/user-products/sniping/type', { params: data });
};

export const apiBidSnipping = (data) => {
  return rootApi.post('/user-products/bidding/sniping', data);
};

export const apiUpdateSnipping = (data, id) => {
  return rootApi.put(`/snipings/update/${id}`, data);
};
