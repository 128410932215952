export const topBarHeight = 64;
export const sideNavWidth = 280;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;
export const dateFormat = 'YYYY-MM-DD';

export const optionTransactionType = [
  {
    label: 'Nạp tiền',
    value: 'DEPOSIT'
  },
  {
    label: 'Rút tiền',
    value: 'WITHDRAW '
  },
  {
    label: 'Thanh toán',
    value: 'PAY '
  },
  {
    label: 'Đặt cọc',
    value: 'EARNEST_MONEY  '
  }
];

export const BidStatus = {
  BIDDING: 'BIDDING',
  WON: 'WON',
  LOST: 'LOST',
  SNIPPING: 'SNIPPING'
};
