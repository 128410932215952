import React, { useState } from 'react';
import './index.css';
import { Button } from 'antd';
import { ReactComponent as Down } from '@assets/images/customer/down.svg';
import { ReactComponent as Up } from '@assets/images/customer/up.svg';
import { Svgs } from '@components/IconSvg';
import { apiTranslateProduct } from '@services/ManageAuctions/AuctionServices';

export const ProductDescription = ({ data }) => {
  const maxLength = 500;
  const [isExpanded, setIsExpanded] = useState(false);
  const [translatedDescription, setTranslatedDescription] = useState(null); // State để lưu nội dung dịch
  const [loading, setLoading] = useState(false); // State để quản lý trạng thái loading
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const handleTranslate = async () => {
    setLoading(true);
    try {
      const param = {
        product_code: data?.detail?.auction_id
      };
      const res = await apiTranslateProduct(param);
      setTranslatedDescription(res?.data?.data); // Cập nhật nội dung dịch
    } catch (error) {
      console.error('Error translating description:', error);
    } finally {
      setLoading(false);
    }
  };
  const descriptionToShow = translatedDescription
    ? translatedDescription
    : isExpanded
    ? data.description
    : `${data.description.substring(0, maxLength)}...`;
  if (data?.description.length <= maxLength) {
    return <p>{data?.description}</p>;
  }
  return (
    <div className="ProductExplanation">
      <div className="ProductExplanation__header">
        <div className="ProductExplanation__title">Mô tả sản phẩm</div>
        <div
          className="ProductExplanation__icon"
          onClick={handleTranslate}
          style={{ cursor: 'pointer' }}>
          <Svgs.TranslateSvg />
          <span className="ProductExplanation__translateText">
            {loading ? 'Đang dịch...' : 'Dịch'}
          </span>
        </div>
      </div>
      <div className="ProductExplanation__body highlightWordSearch">
        <div
          className="ProductExplanation__commentArea"
          dangerouslySetInnerHTML={{
            __html: descriptionToShow
          }}
        />
      </div>
      {data.description.length > maxLength && (
        <div className="ProductExplanation_bot" onClick={toggleReadMore}>
          <div className="ProductExplanation_bot_btn">
            {isExpanded ? 'Thu gọn' : 'Xem thêm'}{' '}
            {isExpanded ? (
              <Up className="ProductExplanation_bot_icon" />
            ) : (
              <Down className="ProductExplanation_bot_icon" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
