import React, { useState } from 'react';
import '../index.css';
import { ReactComponent as Vector } from '@assets/images/user/vector.svg';
import { ReactComponent as Reload } from '@assets/images/adminPage/reload.svg';
import { Button } from 'antd';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { URI_CRAWL } from '@contants/options';
import { useNavigate } from 'react-router-dom';

export const SummaryRight = ({ data, yahoo_id }) => {
  const countingStar = (ratio) => {
    if (ratio < 0) {
      ratio = 0;
    } else if (ratio > 100) {
      ratio = 100;
    }

    return ratio / 20;
  };
  const sellerId = data?.seller?.id;
  const username = data?.seller?.name;
  const avatar = data?.seller?.profile_icon;
  const rating_ratio = data?.seller?.rating_star;
  const rating_count = data?.seller?.rating_count;
  const site = data?.seller?.site;
  const is_favourite = data?.seller?.is_favourite;
  const [favorite, setFavorite] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="summary-right">
      <div className="link-origi">
        <div className="link-origi_row">
          <label className="bold label">Chi tiết sản phẩm</label>
          <Reload />
        </div>
        <div>
          <a
            rel="noreferrer"
            target="_blank"
            className="btn-link c-pointer"
            href={`${URI_CRAWL}/${yahoo_id}`}>
            Xem trên Yahoo!Auction
          </a>
          <Vector />
        </div>
      </div>
      <div className="box-seller">
        <label className="bold">Thông tin người bán</label>
        <div className="bs-info">
          <div
            className="seller-data"
            onClick={() =>
              navigate(
                `/auction/seller/${sellerId}?username=${username}&avatar=${avatar}&rating_ratio=${rating_ratio}&rating_count=${rating_count}&site=${site}&is_favourite=${is_favourite}`
              )
            }
            style={{ cursor: 'pointer' }}>
            <div className="seller-avatar">
              <img alt="" src={data?.seller.profile_icon} />
            </div>
            <div className="seller-person">
              <a>
                <span className="seller-name bold">{data?.seller.name}</span>
              </a>
              <div className="Seller__ratingStarOn">
                {[0, 1, 2, 3, 4].map((item, index) => {
                  return item <
                    countingStar(
                      Number(
                        data?.seller.rating_star.slice(0, data?.seller.rating_star.indexOf('%'))
                      )
                    ) ? (
                    <span key={index} className="star2" />
                  ) : (
                    <span key={index} className="star" />
                  );
                })}
              </div>
              <div className="rate-text">
                <span>
                  <strong>
                    {data?.seller.rating_count !== '' ? data?.seller.rating_count : 0} Đánh giá
                  </strong>
                </span>
              </div>
            </div>
            <div className="favorite-seller">
              <Button
                className="favorite-sellter_btn"
                type="text"
                icon={
                  favorite ? (
                    <HeartFilled style={{ color: '#626D7C' }} />
                  ) : (
                    <HeartOutlined style={{ color: '#626D7C' }} />
                  )
                }
                style={{ borderRadius: '36px' }}
                onClick={() => setFavorite(!favorite)}
                key="favorite"
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="box-support">
        <label className="bold" style={{ marginBottom: 12 }}>
          Hướng dẫn
        </label>
        <div className="sp-link">
          <a
            href="https://jancargo.com/chi-tiet-tin/san-phut-chot-tinh-nang-dau-gia-tu-dong-thong-minh"
            rel="noreferrer"
            target="_blank"
            className="c-pointer">
            Săn phút chót là gì
          </a>
          <a
            href="https://jancargo.com/help/mua-sam-cung-jancargo"
            rel="noreferrer"
            target="_blank"
            className="c-pointer">
            Hướng dẫn mua ngay
          </a>
          <a
            href="https://jancargo.com/help/tra-hang-and-hoan-tien"
            rel="noreferrer"
            target="_blank"
            className="c-pointer">
            Chính sách hoàn tiền
          </a>
        </div>
      </div> */}
    </div>
  );
};
